import customUriParser from './uriParser.js';

export default {
  platform: 'shift4shop',
  customUriParser,
  searchPageUrl: '/search.asp',
  SearchRequestDefaults: {
    pageSize: 24,
  },
  pagination: {
    centralRange: 1,
  },
  product: {
    noImageSrc: '/assets/images/default.jpg',
  },
  facets: {
    rangedFacet: [
      { fields: ['NumberOfLights', 'Width', 'Height', 'Depth'], name: 'sliderFacet' },
      { fields: ['Price'], name: 'priceFacet' },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { selector: '#cm_results', class: 'cm_SearchPage_container' },
      template: 'MainContent',
    },
    {
      name: 'SearchPageCategory',
      type: 'SearchPage',
      location: '.category-products',
      template: 'MainContent',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.site-header #searchBox',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name: A to Z',
        'Name:desc': 'Name: Z to A',
        'DateCreated:desc': 'Newest',
        'OnSale:desc': 'On Sale',
      },
      pageSizes: [6, 12, 24, 48, 96],
    },
    {
      name: 'FacetPanelContainer',
      type: 'FacetPanel',
      location: '#cm_facets',
      template: 'FacetPanelContainer',
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};

function updateCallback() {
  /* eslint-disable */
  // copy-paste from shift4shop core.js
  typeof lozad != 'function' &&
    (jQuery('div.product-items').each(function () {
      var n = 0;
      jQuery('.product-item', this).each(function (t, i) {
        var r = jQuery(i).height();
        r > n && (n = r);
      });
      jQuery(this).attr('data-itemsHeight', n);
    }),
    jQuery('div.product-items').each(function (n, t) {
      var i = jQuery(t).attr('data-itemsHeight');
      i != undefined && i + '' != '' && jQuery(t).find('.product-item').height(i);
    }));

  var maxHeight = 0;
  jQuery('.product-items .product-item .name').each(function () {
    if (jQuery(this).height() > maxHeight) {
      maxHeight = jQuery(this).height();
    }
  });
  jQuery('.product-items .product-item .name').height(maxHeight);

  jQuery('.product-item .quickview').click(function (n) {
    n.preventDefault();
    var t = jQuery(this).parents('.product-item'),
      i = jQuery(t).data('catalogid'),
      r = jQuery(t).data('categoryid');
    quickview('/product.asp?lt_c=1&itemid=' + i + '&qv=1');
  });
  jQuery('.product-item[data-ajaxcart="1"] .add-to-cart').click(function (n) {
    var i, u, e;
    if (
      (n.preventDefault(),
      jQuery(this).blur(),
      (i = jQuery(this).parents('.product-item')),
      !jQuery(i).hasClass('ajaxcart-loading'))
    ) {
      jQuery(i).addClass('ajaxcart-loading');
      var r = jQuery(i).data('catalogid'),
        o = jQuery(i).data('categoryid'),
        t = get_product(r, o),
        s = parseInt(t.Stock),
        f = parseInt(t.InventoryControl),
        h = parseInt(t.InventoryControlGlobal);
      s <= 0 &&
        (f != -1
          ? f == 3 && (window.location.href = 'product.asp?itemid=' + r)
          : h == 3 && (window.location.href = 'product.asp?itemid=' + r));
      u = !1;
      t.OptionSetList && ((e = JSON.parse(t.OptionSetList)), e.length > 0 && (u = !0));
      isQuickviewEnabled() && (u || t.IsOptionRequiredForCategory || t.HasCategoryWideProductOptions)
        ? (quickview('/product.asp?lt_c=1&itemid=' + r + '&qv=1'), jQuery(i).removeClass('ajaxcart-loading'))
        : !isQuickviewEnabled() && (u || t.IsOptionRequiredForCategory || t.HasCategoryWideProductOptions)
          ? (window.location.href =
              t.ProductLink.indexOf('https://') >= 0 || t.ProductLink.indexOf('http://') >= 0
                ? t.ProductLink
                : '/' + t.ProductLink)
          : add_to_cart(t.CatalogID, t.SKU, 0, t.Price, 1, [], !1, i);
    }
  });

  var categories = [];
  $('.breadcrumnb li[itemprop="itemListElement"]').each(function (index, element) {
    if (index > 0) {
      categories.push($(element).find('span[itemprop="name"]').text());
    }
  });

  var items = [];
  $('.category-products .product-item').each(function (index, element) {
    var item = {
      item_name: $(element).find('.name a').text(),
      item_id: $(element).data('catalogid'),
      price: ($(element).find('.sale-price').length
        ? $(element).find('.sale-price').text()
        : $(element).find('.regular-price').text()
      ).replace(/[^\d|.]/g, ''),
      item_list_name: 'category',
      item_list_id: 'category0',
      index: index + 1,
    };

    categories.forEach(function (category, indexCategory) {
      item['item_category' + (indexCategory > 0 ? indexCategory + 1 : '')] = category;
    });

    items.push(item);
  });

  var data = {
    event: 'view_item_list',
    ecommerce: {
      item_list_name: 'category',
      item_list_id: 'category0',
      items: items,
    },
  };

  window.dataLayer = window.dataLayer || [];
  dataLayer.push(data);

  $('.category-products .product-item .img, .category-products .product-item .name').on('click', function () {
    var categories = [];
    $('.breadcrumnb li[itemprop="itemListElement"]').each(function (index, element) {
      if (index > 0) {
        categories.push($(element).find('span[itemprop="name"]').text());
      }
    });

    var productItem = $(this).closest('.product-item');

    var data = {
      event: 'select_item',
      ecommerce: {
        item_list_name: 'category',
        item_list_id: 'category0',
        items: [
          {
            item_name: productItem.find('.name a').text(),
            item_id: productItem.data('catalogid'),
            price: (productItem.find('.sale-price').length
              ? productItem.find('.sale-price').text()
              : productItem.find('.regular-price').text()
            ).replace(/[^\d|.]/g, ''),
            item_list_name: 'category',
            item_list_id: 'category0',
            index: 1,
          },
        ],
      },
    };

    categories.forEach(function (category, indexCategory) {
      data['ecommerce']['items'][0]['item_category' + (indexCategory > 0 ? indexCategory + 1 : '')] =
        category;
    });

    window.dataLayer = window.dataLayer || [];
    dataLayer.push(data);
  });

  $('.product-item .add-to-cart').on('click', function () {
    var productItem = $(this).closest('.product-item');

    var data = {
      event: 'add_to_cart',
      ecommerce: {
        currency: 'USD',
        value: (productItem.find('.sale-price').length
          ? productItem.find('.sale-price').text()
          : productItem.find('.regular-price').text()
        ).replace(/[^\d|.]/g, ''),
        items: [
          {
            item_name: productItem.find('.name a').text(),
            item_id: productItem.data('catalogid'),
            price: (productItem.find('.sale-price').length
              ? productItem.find('.sale-price').text()
              : productItem.find('.regular-price').text()
            ).replace(/[^\d|.]/g, ''),
            item_list_name: 'category',
            item_list_id: 'category0',
            index: 1,
            quantity: 1,
          },
        ],
      },
    };

    window.dataLayer = window.dataLayer || [];
    dataLayer.push(data);
  });
  /* eslint-enable */
}

export default {
  CHIPS_DISCARD: '<span class="icon-cancel"></span>',
  FACET_INPUT_CLASS: 'form-control',
  SHOW_MORE: 'Show all',
  SHOW_LESS: 'Show only top',
  START_OVER: 'Clear all',

  PRODUCT_REPEATER_CLASS: 'product-items product-items-3',

  SHOW_RESULTS_CLASS: 'btn btn-default',
  BUTTON_PRIMARY_CLASS: 'btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'btn btn-default',

  SEARCH_BOX_FORM_CLASS: 'search-form',
  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_BUTTON_CLASS: 'search-submit btn btn-default',
  SEARCH_BOX_PLACEHOLDER: 'What are you shopping for?',
};

//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-396:_3548,_9188,_8904,_9764,_3148,_9916,_1896,_2672;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-396')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-396', "_3548,_9188,_8904,_9764,_3148,_9916,_1896,_2672");
        }
      }catch (ex) {
        console.error(ex);
      }